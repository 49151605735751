<template>
  <v-app>
    <layout-app-bar />

    <layout-drawer />

    <layout-view />

    <layout-fab />
  </v-app>
</template>

<script>
export default {
  name: 'LayoutsRoot',
  components: {
    LayoutAppBar: () => import('./AppBar.vue'),
    LayoutDrawer: () => import('./Drawer.vue'),
    LayoutFab: () => import('./Fab.vue'),
    LayoutView: () => import('./View.vue')
  }
};
</script>
